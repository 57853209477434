@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.terms-and-conditions {
  &__content {
    padding: 32px;
    max-width: 1440px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul {
      padding-left: 25px;
    }

    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  sm1: 450px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);

$note-line-height: 20px;
:root {
  --header-offset: 74px;
  --n-header-offset: -102px;
  --n-header-without-breadcrumb: -74px;

  --card-shadow-padding: 34px;
  --n-card-shadow-padding: -34px;
}
// ============= Mixins =============

@mixin shadow {
  box-shadow: 0 4px 14px rgba(0, 66, 138, 0.1);
}

@mixin red-blue-text {
  background: linear-gradient(106.96deg, #e21e4b 0%, #004691 118.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// ============= New Colors =============
//Text
$text-primary: #333333;
$text-secondary: #959ba1;
$text-disable: #d5dce0;
$text-white: #fff;
$text-accentRed: #e21e4b;
$text-accentBlue: #004691;
$text-successGreen: #24b275;
$text-error: #d91212;

//Background
$bg-black: #2b2f35;
$bg-blackApp: #000000;
$bg-gray: #f5f8fa;
$bg-white: #fff;
$bg-darkgray: #eef3f5;
$bg-blue: #e6edf4;

//Button
$btn-accentRed: #e21e4b;
$btn-redHover: #c01c42;
$btn-redPress: #971734;
$btn-disable: #d5dce0;
$btn-black: #333333;
$btn-white: #fff;
$btn-whiteHover: #f0f3f5;
$btn-whitePress: #e7e9eb;

//Elements
$element-accentRed: #e21e4b;
$element-red60: #ee7893;
$element-red40: #f3a5b7;
$element-red10: #fce9ed;

$element-accentBlue: #004691;
$element-blue60: #6690bd;
$element-blue40: #99b5d3;
$element-blue10: #e6edf4;

$element-black: #333333;
$element-gray: #959ba1;
$element-lightgray: #e7e9eb;
$element-white: #fff;
$element-green: #24b275;
$element-disabled: #bfc7cb;

$element-sri-lanka: #3bbe97;
$element-kribati: #789eff;
$element-violet: #8747f4;
$element-royal-blue: #4d60f4;
$element-biloba-flower: #b991eb;
$element-picton-blue: #47a1f4;
$element-indigo: #546ed0;
$element-amethyst: #8150d1;
$element-jaffa: #f49a47;
$element-carnation: #f44d4d;
$element-fountain-blue: #57c3c3;
$element-keppel: #3caa9d;
$element-daisy: #4c2094;
$element-electric-violet: #712eff;

$input-focus-border: 1px solid $element-blue60;
$input-border: 1px solid $bg-gray;

/// ============= Deprecated Colors =============
$text-gray-secondary: #959ba1;
$text-red: #ee7893;
$element-lightblue: #99b5d3;
$element-blue: #6690bd;
$element-accentblue: #004691;
$element-black: #333333;
$element-white: #fff;
$element-gray: #868d94;
$element-red: #e21e4b;
$element-green: #24b275;
$bg-gray: #f5f8fa;
$bg-white: #fff;
$bg-lightgray: #e8edf2;
$bg-red: #e21e4b;

/// ============= Animations =============

$animation-duration: 300ms;
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-600px);
  }
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

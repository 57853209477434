@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.login-modal {
  padding: 32px 16px;

  &__icon {
    display: block;
    margin: 0 auto 32px;
  }

  &__title {
    @include headline-6;
    margin-bottom: 48px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__btn {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    &__title {
      @include headline-5;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 48px 150px;
  }
}

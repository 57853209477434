@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.base-form {
  $this: &;

  &__title {
    @include headline-5;
    margin-bottom: 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  & &__input {
    width: 100%;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__select {
    width: 100%;
    cursor: pointer;

    input,
    label {
      cursor: pointer;
      pointer-events: none;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    padding-right: 16px;
    & * {
      stroke: var(--palette-secondary);
    }
  }

  & &__btn {
    width: 100%;
    margin-bottom: 16px;
  }

  &__security {
    @include caption-1;
    color: var(--palette-secondary);
  }

  &.__dark-mode {
    #{$this} {
      &__title {
        color: var(--palette-constant-white);
      }
      &__input {
        & > * {
          background-color: transparent;
          input {
            background: unset;
            color: var(--palette-constant-white);
          }
          label {
            color: var(--palette-constant-white);
          }
        }
      }
      &__security {
        color: var(--palette-disable);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__title {
      @include headline-4;
      text-align: center;
    }

    &__form {
      max-width: 457px;
      margin: 0 auto;
    }

    & &__btn {
      margin-bottom: 20px;
    }

    &__security {
      text-align: center;
    }
  }
}

@mixin headline-lg {
  font-size: 80px;
  line-height: 100px;
  font-weight: 650;
}

@mixin headline-0 {
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
}

@mixin headline-1 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
}

@mixin headline-2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

@mixin headline-3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

@mixin headline-4 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}

@mixin headline-5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@mixin headline-6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

@mixin headline-7 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

@mixin h7 {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

@mixin subtitle-1 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

@mixin subtitle-1-xs {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}

@mixin subtitle-2 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

@mixin subtitle-3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@mixin body-xl {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

@mixin body-0 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@mixin body-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin body-1-xs {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

@mixin body-2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@mixin body-3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin caption-1 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@mixin caption-mini {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

@import 'src/styles/mixins/gradients';
@import 'src/styles/variables';

.ingos-btn--color-primary {
  &.main-button {
    &_gradient {
      border: 0;
      @include gradient-background-purple;
      transition: background 300ms ease;

      &:hover {
        @include gradient-background(46.25deg, $element-violet, $element-violet);
      }
      &:active {
        @include gradient-background(46.25deg, $element-daisy, $element-electric-violet);
      }
    }

    &_secondary-gradient {
      border: unset;
      @include gradient-text(var(--palette-gradient-accent));
      color: $element-violet;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        border: none;
        border-radius: 8px;
        padding: 1.5px;
        background: linear-gradient(117.58deg, $element-violet 12.89%, $element-royal-blue 96.68%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      path {
        stroke: currentColor;
      }

      &:hover {
        @include gradient-text(var(--palette-gradient-accent));
        border: 1.5px solid $element-violet;

        &:before {
          content: none;
        }
      }

      &:active {
        @include gradient-text(var(--palette-gradient-accent));
        color: $element-daisy;
        border: unset;

        &:before {
          content: '';
          border-image-source: linear-gradient(
            46.25deg,
            $element-daisy 0%,
            $element-electric-violet 100%
          );
        }
      }
    }
  }
}

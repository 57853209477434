@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.base-legal-info {
  width: 100%;

  &__title {
    @include subtitle-2;
    color: var(--palette-constant-white);
  }

  &__text {
    @include body-3;
    color: var(--palette-secondary);
    padding-right: 16px;
    ul {
      padding: 0;
    }
    br {
      content: '';
      display: block;
      height: 8px;
    }

    p {
      margin: 8px 0;
    }
  }

  @include media-breakpoint-up(xxl) {
    &__title {
      @include subtitle-1;
    }
  }
}

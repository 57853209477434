@font-face {
  font-family: IngoStem;
  font-weight: 300;
  src: url('../assets/fonts/IngoStem-Light.otf') format('opentype'),
    url('../assets/fonts/IngoStem-Light.woff2') format('woff2'),
    url('../assets/fonts/IngoStem-Light.woff') format('woff');
}

@font-face {
  font-family: IngoStem;
  font-weight: 400;
  src: url('../assets/fonts/IngoStem-Regular.otf') format('opentype'),
    url('../assets/fonts/IngoStem-Regular.woff2') format('woff2'),
    url('../assets/fonts/IngoStem-Regular.woff') format('woff');
}

@font-face {
  font-family: IngoStem;
  font-weight: 500;
  src: url('../assets/fonts/IngoStem-Medium.otf') format('opentype'),
    url('../assets/fonts/IngoStem-Medium.woff2') format('woff2'),
    url('../assets/fonts/IngoStem-Medium.woff') format('woff');
}

@font-face {
  font-family: IngoStem;
  font-weight: 700;
  src: url('../assets/fonts/IngoStem-Bold.otf') format('opentype'),
    url('../assets/fonts/IngoStem-Bold.woff2') format('woff2'),
    url('../assets/fonts/IngoStem-Bold.woff') format('woff');
}

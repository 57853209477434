@import "src/styles/sass";

.breadcrumbs {
  $self: &;

  display: flex;
  align-items: center;

  &__link {
    display: flex;
    align-items: center;
    margin-right: 7px;
    color: var(--palette-secondary);
    @include caption-1;

    #{$self}__dot {
      background: var(--palette-secondary);
    }

    &:hover {
      color: var(--palette-secondary);

      #{$self}__dot {
        background: var(--palette-secondary);
      }
    }

    &--active {
      color: var(--palette-constant-purple-medium);
      pointer-events: none;

      #{$self}__dot {
        background: var(--palette-constant-purple-medium);
      }
    }
  }

  &__dot {
    width: 2px;
    height: 2px;
    margin-right: 7px;
    border-radius: 50%;
  }
}

@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/styles/variables';

$offset-bottom: 56px;
.header-content {
  &__inner {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: $offset-bottom;
  }

  &__categories {
    border-bottom: 1px solid var(--palette-soft);
  }

  &__category {
    margin-bottom: 32px;
  }

  &__list-links {
    margin-top: 32px;
  }

  &__additional-categories {
    margin-top: 32px;
  }

  &__additional-category {
    margin-bottom: 16px;
  }

  @include media-breakpoint-up(lg) {
    &__content {
      display: flex;
      align-items: flex-start;
    }

    &__slot {
      position: absolute;
      bottom: $offset-bottom;
    }

    &__categories {
      display: flex;
      border-right: 1px solid var(--palette-soft);
      border-bottom: none;
    }

    &__category {
      min-width: 216px;
      margin-right: 24px;
      margin-bottom: 0;

      &:last-child {
        min-width: 296px;
      }
    }

    &__list-links {
      margin-top: 0;
      margin-left: 24px;
      min-width: 131px;
    }

    &__additional-categories {
      margin-top: 0;
      margin-left: 24px;
    }

    &__other-links {
      padding-left: 28px;
    }
  }

  @include media-breakpoint-up(xxl) {
    &__category {
      min-width: 280px;
      margin-right: 32px;

      &:last-child {
        min-width: 352px;
      }
    }
    &__list-links {
      margin-left: 32px;
    }
    &__additional-categories {
      margin-left: 32px;
    }
  }
}

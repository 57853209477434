.candybox-button {
  $this: &;
  width: 20px;
  height: 20px;
  margin-left: auto;

  & * {
    fill: var(--palette-constant-black);
  }

  &__icon-open-hover {
    display: none;
  }

  &:hover {
    * {
      fill: var(--palette-constant-purple-medium);
    }
    #{$this}__icon-open {
      display: none;
    }
    #{$this}__icon-open-hover {
      display: block;
    }
  }

  &.__dark-mode {
    * {
      fill: var(--palette-constant-white);
    }

    &:hover {
      * {
        fill: var(--palette-constant-purple-medium);
      }
    }
  }
}

@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.additional-header-category {
  &__headline {
    display: flex;
    justify-content: space-between;
    color: var(--palette-constant-blue);
    margin-bottom: 16px;
  }

  &__title {
    @include body-3;
    text-decoration: none;
    color: var(--palette-constant-blue);
    margin-bottom: 0;

    &:hover {
      color: var(--palette-constant-purple-medium);
    }
  }

  @include media-breakpoint-up(lg) {
    &__headline {
      margin-bottom: 12px;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &__content {
      padding-left: 28px;
    }
  }

  @include media-breakpoint-up(xxl) {
    &__title {
      @include body-1;
    }
  }
}

@import 'src/styles/mixins/typography';

.header-links {
  &__link {
    @include body-2;
    margin-left: 24px;
    color: var(--palette-constant-black);

    &:hover {
      color: var(--palette-constant-purple-medium);
    }

    &.__dark-mode {
      color: var(--palette-constant-white);
    }
  }
}

@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.base-header {
  $this: &;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 16px 0;

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 11;
  }

  &__logo {
    display: block;
    height: 24px;
    img {
      height: 100%;
    }
  }

  &__links {
    margin: 0 auto;
  }

  &__content {
    background: var(--palette-constant-white);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 76px;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    margin-left: auto;
  }

  &__user-btn {
    &:hover {
      * {
        stroke: var(--palette-constant-purple-medium);
      }
    }

    &.__dark-mode {
      * {
        stroke: var(--palette-constant-white);

        &:hover {
          * {
            stroke: var(--palette-constant-purple-medium);
          }
        }
      }
    }
  }

  &__contacts {
    margin-top: 32px;

    & > * {
      @include body-3;
      display: block;
      color: var(--palette-constant-blue);
      margin-bottom: 12px;

      &:hover {
        color: var(--palette-constant-purple-medium);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-schedule {
      display: flex;
      & * {
        stroke: var(--palette-constant-blue);
      }
      &:hover {
        color: var(--palette-constant-blue);
      }

      &-informer {
        transform: unset;
        bottom: 100%;
        max-width: 240px;
        left: 0 !important;
        right: auto !important;
      }
    }
    &-phone {
      @include body-0;
    }
  }

  #{$this} {
    &__btn {
      background: transparent;
      border-color: var(--palette-constant-blue);
      color: var(--palette-constant-blue);
      margin-right: 16px;

      &:hover,
      &:active {
        color: var(--palette-constant-white);
        background: var(--palette-constant-blue);
        border-color: var(--palette-constant-blue);
      }

      &:first-child {
        flex-grow: 1;
      }

      &:last-child {
        flex-grow: 3;
        margin-right: 0;
      }

      &.__dark-mode {
        border-color: var(--palette-constant-white);
        color: var(--palette-constant-white);

        &:hover,
        &:active {
          color: black;
          background: var(--palette-constant-white);
          border-color: var(--palette-constant-white);
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__buttons {
      flex-direction: row-reverse;
      gap: 48px;
    }

    #{$this}__btn {
      margin-right: 8px;
    }

    &__logo {
      height: 40px;
    }

    &__content {
      padding-top: 120px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-top: 128px;
    }

    &__contacts {
      & > * {
        margin-bottom: 8px;
      }
    }

    &__buttons {
      margin-left: unset;
    }
  }
}

.header-link {
  @include body-3;
  display: block;
  color: var(--palette-constant-blue);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: var(--palette-constant-purple-medium);
  }

  &.__dark-mode {
    color: var(--palette-constant-white);
    &:hover {
      color: var(--palette-constant-purple-medium);
    }
  }

  @include media-breakpoint-up(xxl) {
    @include body-1;
  }
}

.header-small-link {
  @include caption-1;
  display: block;
  color: var(--palette-constant-blue);
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: var(--palette-constant-purple-medium);
  }

  &.__dark-mode {
    color: var(--palette-constant-white);
    &:hover {
      color: var(--palette-constant-purple-medium);
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 8px;
  }

  @include media-breakpoint-up(xxl) {
    @include body-3;
  }
}

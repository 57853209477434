@import 'src/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.common-container {
  padding: 0 16px;

  @include media-breakpoint-up(md) {
    padding: 0 44px;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 32px;
  }

  @include media-breakpoint-up(xxl) {
    padding: 0 112px;
  }

  &.hidden-overflow {
    overflow: hidden;
  }
}

.common-wrapper {
  max-width: 1216px;
  margin: 0 auto;
}

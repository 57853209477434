.layout {
  &-main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding-top: var(--header-offset);
  }

  &-breadcrumbs {
    position: relative;
    z-index: 2;
    &_inverted {
      .ingos-breadcrumbs__link:not(.active) {
        color: var(--palette-constant-white);
      }
    }
  }
}

@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.base-footer {
  padding: 32px 0;
  background: var(--palette-constant-footer-black);

  &__category {
    margin-bottom: 24px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 56px;

    &-phone {
      @include headline-6;
      margin-bottom: 4px;
      color: var(--palette-constant-white);
      &:hover {
        color: var(--palette-constant-purple-medium);
      }
    }

    &-schedule {
      @include body-3;
      color: var(--palette-secondary);
      padding-bottom: 12px;
      border-bottom: 1px solid #45494e;
      & * {
        stroke: var(--palette-secondary);
      }

      &-informer {
        max-width: 240px;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      margin-top: 12px;
      @include subtitle-3;
      color: var(--palette-constant-white);

      &:hover {
        color: var(--palette-constant-purple-medium);
      }

      &-icon {
        display: flex;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        & * {
          stroke: var(--palette-constant-purple-medium);
        }
        & > * {
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  &__additional-category {
    margin-top: 56px;
    padding-bottom: 24px;
    border-bottom: 1px solid #45494e;

    &-headline {
      justify-content: flex-start;
      color: var(--palette-constant-white);
      margin-bottom: 12px;
      & * {
        stroke: var(--palette-constant-white);
      }
      & > * {
        margin-right: 16px;
        & + * {
          transform: rotate(-90deg);
        }
      }
    }

    & &-item {
      @include body-2;
      color: var(--palette-secondary);
    }
  }

  &__info {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #45494e;

    &._additional-legal-info {
      & > * {
        &:first-child {
          margin-bottom: 48px;
        }
      }
    }
  }

  &__copyright {
    @include body-3;
    margin-top: 56px;
    color: var(--palette-constant-white);
    &-links {
      margin-top: 24px;
    }
  }

  &__social-link {
    margin-right: 20px;
    & * {
      stroke: var(--palette-constant-white-white);
    }
    &:hover {
      & * {
        stroke: var(--palette-constant-purple-medium);
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 36px 0;

    &__content-top {
      display: flex;
      flex-wrap: wrap;
    }

    &__category {
      min-width: 240px;
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__list-links {
      min-width: 150px;
    }

    &__contacts {
      width: 100%;
    }

    &__additional-category {
      padding-bottom: 36px;

      &-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -12px;
      }

      &-item {
        display: flex;
        &:not(:last-child):after {
          color: var(--palette-secondary);
          content: '|';
          margin: 0 16px;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    &__info {
      display: flex;
      gap: 24px;

      &._additional-legal-info {
        & > * {
          &:first-child {
            margin-bottom: 0;
          }
          width: 50%;
        }
      }
    }

    &__copyright {
      display: flex;
      justify-content: space-between;
      &-links {
        margin: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__category {
      margin-right: 32px;
    }

    &__contacts {
      width: unset;
      align-items: flex-end;
      margin-top: 0;
      margin-left: auto;
      &-schedule {
        &-informer {
          right: 0;
          left: auto;
        }
      }
    }

    &__additional-category {
      margin-top: 64px;
      &-headline {
        flex-direction: row;
      }

      &-content {
        padding: 0;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    &__category {
      min-width: 280px;
    }

    &__contacts {
      &-phone {
        @include headline-5;
      }
      &-schedule {
        @include body-1-xs;
      }
      &-link {
        @include subtitle-2;
      }
    }

    &__additional-category {
      &-headline {
        @include subtitle-1;
      }
    }
  }
}

@import 'src/styles/variables';

.base-inform {
  $this: &;
  position: relative;

  &__icon {
    width: 18px;
    height: 18px;
    color: var(--palette-constant-blue-60);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;

    &:hover + #{$this}__informer {
      display: block;
    }
  }

  &__informer {
    min-width: 276px;
    width: max-content;
    display: none;
    position: absolute;
    background: radial-gradient(
      137.99% 1274.91% at -1.37% -3.89%,
      rgba(244, 249, 255, 0.9) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    z-index: 1;
    &:hover {
      display: block;
    }
  }

  &._right {
    #{$this} {
      &__container {
        flex-direction: row-reverse;
      }
      &__informer {
        left: unset;
        right: 0;
        translate: unset;
      }
    }
  }
}

@mixin gradient-background($deg, $color1, $color2) {
  background: linear-gradient($deg, $color1 0%, $color2 100%);
}

@mixin gradient-background-purple {
  @include gradient-background(135deg, #8747f4, #4d60f4);
}

@mixin text-clip {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin gradient-text($gradient) {
  background: $gradient;
  @include text-clip;
}

@mixin gradient-text-purple {
  @include gradient-text(var(--palette-gradient-accent));
}

@use 'sass:map';
@import 'src/styles/fonts';
@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import 'ii-ui-kit/dist/styles.css'; // styles and fonts from ii-ui-kit

html,
body {
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  background-color: var(--palette-bg-gray-2);
  scroll-behavior: smooth;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'IngoStem', Arial, Verdana, Courier, serif;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 600;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-block {
  display: block;
}

.nowrap {
  white-space: nowrap;
}

svg:focus {
  outline: none;
}

a,
.link {
  text-decoration: none;
  color: var(--palette-constant-purple-medium);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: var(--palette-secondary-purple-pressed-1);
  }

  &.__dark-mode {
    color: var(--palette-constant-purple-medium);

    &:active {
      color: var(--palette-secondary-purple-pressed-1);
    }
  }
}

.pointer {
  cursor: pointer;
}

.no-link {
  &:hover {
    text-decoration: none;
  }
}

.note {
  font-size: 14px;
  line-height: $note-line-height;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.blue-back {
  background: linear-gradient(90deg, #d8e1ea 0%, #c4d3df 100%);
}
.grey-back {
  background: linear-gradient(90deg, #e1e4e7 0%, #7f8387 100%);
}
.black-back {
  background: linear-gradient(90deg, #262626 0%, #7f8387 100%);
}
.purple-back {
  background: linear-gradient(90deg, #ded8ea 0%, #d7e3ed 100%);
}
.light-blue-back {
  background: linear-gradient(90deg, #d7f5ff 0%, #cfe4f0 100%);
}
.dark-blue-back {
  background: linear-gradient(90deg, #b5d9fc 0%, #dee9fe 100%);
}

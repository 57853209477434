@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

.base-form-status {
  &__error {
    flex-direction: column;
    text-align: center;
    margin-bottom: 32px;

    &-title {
      @include headline-5;
      margin-top: 16px;
      margin-bottom: 4px;
    }
    &-text {
      @include body-1;
    }
  }

  &__success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &-icon {
      margin-bottom: 48px;
    }

    &-name {
      @include headline-4;
      margin-bottom: 16px;
    }

    &-text {
      @include body-1;
    }
  }

  @include media-breakpoint-up(md) {
    &__error {
      margin-bottom: 32px;

      &-icon {
        margin-right: 30px;
        width: 32px;
        height: 32px;
      }
      &-title {
        @include headline-3;
      }
    }

    &__success {
      padding: 115px 0;
      &-name {
        @include headline-2;
      }
    }
  }
}

@import 'src/styles/variables';
@import 'src/styles/mixins/typography';
@import '~bootstrap/scss/mixins/breakpoints';

$padding-x-m: 16px;
$padding-x-md: 32px;
$padding-x-xl: 70px;

.fos-modal {
  &__content {
    padding: 10px 16px 32px;
    max-width: calc(457px + #{$padding-x-m * 2});
    margin: 0 auto;
  }

  &__title {
    @include headline-6;
    margin-bottom: 16px;
    text-align: center;
  }

  &__subtitle {
    @include body-1;
    margin-bottom: 32px;
    text-align: center;
  }

  & &__success-btn {
    margin-top: 40px;
    width: 100%;
  }

  &__status {
    margin: 0 auto;
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding: 32px;
      max-width: calc(457px + #{$padding-x-md * 2});
    }
  }

  @include media-breakpoint-up(lg) {
    &__title {
      @include headline-5;
      margin-bottom: 4px;
    }

    &__subtitle {
      margin-bottom: 48px;
    }
  }
  @include media-breakpoint-up(xl) {
    &__content {
      padding: 70px;
      max-width: calc(457px + #{$padding-x-xl * 2});
    }
  }
}
